@import "./../../styles/colors";
@import "./../../styles/sizes";

.stats-heading {
    display: flex;
    justify-content: space-between;
}

.stats-line {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 8px;

    &__text-container {
        margin-bottom: 4px;

        label {
            font-weight: bold;
            padding-right: 32px;
            display: block;
        }
    }

    &__progress-container {
        width: 100%
    }

    &--indent-1 {
        padding-left: 4vw;
    }
    
    &--indent-2 {
        padding-left: 8vw;
    }
}

@media (min-width: hero-grid-width(3, "medium")) {
    .stats-line {
        &__text-container {
            label {
                display: inline-block;
            }
        }
    }
}