@import "./../../styles/colors";

.progress-bar {
    &__outer {
        width: 100%;
        background-color: $progress-bar--background-color;

        &--small {
            height: 16px;

            .progress-bar__text-container {
                top: -19px;
                font-size: 13px;
            }
        }

        &--large {
            height: 32px;
        }
    }

    &__inner {
        background-color: $progress-bar--main-color;
        height: 100%;
        width: 0%;
        transition: width 0.4s linear;
    }

    &__text-container {
        position: relative;
        top: -29px;
        display: flex;
        justify-content: center;
    }

    &__text {
        color: $site--font-color-alt;
    }
}